import './App.css'
import Scheduler from './Components/Scheduler';

function App() {
  return (
    <div className="container">
      <Scheduler />
    </div>
  );
}

export default App;
