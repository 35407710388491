import * as React from 'react';
import {
    ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, TimelineMonth, TimelineYear,
    ViewsDirective,
    ViewDirective,
    ResourcesDirective, ResourceDirective,
    DragAndDrop, Resize
} from '@syncfusion/ej2-react-schedule';
import { DataManager, UrlAdaptor, } from '@syncfusion/ej2-data'
import { registerLicense } from '@syncfusion/ej2-base'
import { Internationalization } from '@syncfusion/ej2-base';


registerLicense(process.env.REACT_APP_syncfusion_api_key)

const Scheduler = () => {

    const remoteData = new DataManager({
        // url: 'http://localhost:3080/calendar/getData',
        // crudUrl: 'http://localhost:3080/calendar/crudActions',
        url: 'https://prisma.tiggaa.co.uk/calendar/getData',
        crudUrl: 'https://prisma.tiggaa.co.uk/calendar/crudActions',
        adaptor: new UrlAdaptor(),
        crossDomain: true
    })

    const resourceDataSource = [
        { Name: 'Mum & Dad', Id: 1, Color: '#F07857' }, // Orange
        { Name: 'Sarah & Mark', Id: 2, Color: '#ffaa00' }, // Gold
        { Name: 'Jo & Paul', Id: 3, Color: '#0aedf9' }, // Blue
        { Name: 'Robert & Jo', Id: 4, Color: '#BE398D' }, // Magenta
        { Name: 'Rich & Eleanor', Id: 5, Color: '#4FB06D' }, // Green
        { Name: 'Birthdays & Anniversaries', Id: 6, Color: '#BF2C34' }, // Indigo
    ]

    const instance = new Internationalization();
    const getTimeString = (value) => {
        return instance.formatDate(value, { skeleton: 'hm' });
    }
    const eventTemplate = (props) => {
        const secondaryColor = { background: props.SecondaryColor };
        const primaryColor_1 = { background: props.PrimaryColor };
        const primaryColor_2 = { background: props.PrimaryColor };
        return (<div className="template-wrap" style={secondaryColor}>
            <div className="subject" style={primaryColor_1}>{props.Subject} - {props.FamilyId}</div>
            <div className="time" style={primaryColor_2}>
                Time: {getTimeString(props.StartTime)} - {getTimeString(props.EndTime)}</div>
        </div>);
    }

    const fieldsData = {
        id: 'Id',
        subject: { name: 'Subject' },
        isAllDay: { name: 'IsAllDay' },
        startTime: { name: 'StartTime' },
        endTime: { name: 'EndTime' },
        FamilyId: { name: 'FamilyId', defaultValue: 1 },
    }

    const eventSettings = { dataSource: remoteData, fields: fieldsData, template: eventTemplate }
    const group = { byGroupID: false, allowGroupEdit: true, resources: ['Families'] }

    return (
        <div >
            <ScheduleComponent
                currentView='TimelineMonth'
                height='750px'
                eventSettings={eventSettings}
            // group={group}
            >

                <ViewsDirective>
                    <ViewDirective option='Week' />
                    <ViewDirective option='Month' />
                    <ViewDirective option='TimelineMonth' />
                    <ViewDirective option='TimelineMonth' displayName='Grouped Month' group={group} />
                    <ViewDirective option='TimelineYear' />
                </ViewsDirective>

                <ResourcesDirective>
                    <ResourceDirective
                        field='FamilyId'
                        title='Family'
                        name='Families'
                        textField='Name'
                        idField='Id'
                        colorField='Color'
                        allowMultiple={true}
                        dataSource={resourceDataSource} />
                </ResourcesDirective>

                <Inject services={[Day, Week, WorkWeek, Month, Agenda, TimelineMonth, TimelineYear, DragAndDrop, Resize]} />
            </ScheduleComponent>
        </div >
    );
}
export default Scheduler